<template>
    <div>
        <b-card>
            <b-card-header
                class="teacher-details-card__header position-relative"
                header-class="p-0 mb-2"
            >
                <div class="teacher-details-card__title-container d-flex">
                    <unicon name="user-circle" width="22" height="22" />
                    <h4 class="teacher-details-card__title ml-1 mb-0">
                        تفاصيل الإستاذ
                    </h4>
                </div>
                <div
                    class="teacher-details-card__teacher-status-container d-flex align-items-center"
                >
                    <div
                        class="teacher-details-card__teacher-status-title d-flex"
                    >
                        حالة الحساب :
                    </div>
                    <b-badge
                        pill
                        :variant="teacherDto.isBlocked ? 'danger' : 'success'"
                    >
                        {{ teacherDto.isBlocked ? " غير فعال" : " فعال" }}
                    </b-badge>
                </div>
            </b-card-header>
            <ValidationObserver ref="form">
                <b-row>
                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'الاسم و الكنية مطلوب',
                                },
                            ]"
                            name="name"
                            placeholder="أدخل الاسم و الكنية "
                            label="الاسم و الكنية"
                            v-model="teacherDto.name"
                        ></ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            :rules="[
                                { type: 'required', message: 'الاسم مطلوب' },
                                {
                                    type: 'email',
                                    message: 'يرجى ادخال بريد الكتروني صحيح',
                                },
                            ]"
                            name="email"
                            placeholder="ادخل البريد الالكتروني"
                            label="البريد الالكتروني"
                            v-model="teacherDto.email"
                        ></ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            name="password"
                            placeholder="أدخل  كلمة السر الجديدة"
                            label=" كلمة السر الجديدة"
                            v-model="teacherDto.newPassword"
                        ></ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'رقم الهاتف مطلوب',
                                },
                                {
                                    type: 'mobile',
                                    message: 'رقم الهاتف غير صالح',
                                },
                            ]"
                            name="phoneNumber"
                            placeholder="    ادخل رقم الوبايل"
                            label="  رقم الموبايل "
                            v-model="teacherDto.phoneNumber"
                        ></ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            name="نسبة الاستاذ"
                            placeholder="أدخل نسبة الاستاذ"
                            label=" نسبة الاستاذ"
                            v-model="teacherDto.rate"
                        ></ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="2">
                        <ek-input-text
                            readonly
                            name="تاريخ الاشتراك"
                            placeholder=" ادخل تاريخ الاشتراك "
                            label="تاريخ الاشتراك"
                            :value="formDate(teacherDto.dateCreated)"
                        ></ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="2">
                        <ek-input-text
                            readonly
                            name="عدد الاشتراكات"
                            placeholder="ادخل عدد الاشتراكات"
                            label="عدد الاشتراكات"
                            v-model="teacherDto.subCount"
                        ></ek-input-text>
                    </b-col>
                    <b-col cols="12" md="6" lg="2">
                        <ek-input-textarea
                            v-model="teacherDto.description"
                            name="وصف الأستاذ"
                            placeholder="ادخل وصف الأستاذ "
                            label="الوصف"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'وصف الأستاذ مطلوب',
                                },
                            ]"
                        ></ek-input-textarea>
                    </b-col>
                    <b-col cols="12" md="6" lg="2">
                        <ek-input-image
                            name=" file"
                            label=" صورة الأستاذ"
                            :image="teacherDto.imageUrl"
                            @input="getFile($event)"
                        >
                            <h5>انقر لتحميل صورة الأستاذ</h5>
                        </ek-input-image>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #footer>
                <div class="w-100 d-flex justify-content-between">
                    <div>
                        <b-button variant="primary" @click="submit"
                            >تعديل</b-button
                        >
                        <b-button
                            variant="outline-primary "
                            class="mx-1"
                            to="/admin/teachers"
                            >تراجع</b-button
                        >
                    </div>
                    <div>
                        <b-button
                            @click="isBlock"
                            variant="outline-danger"
                            class="mr-1"
                            >{{ teacherDto.isBlocked ? "الغاء الحظر" : "حظر" }}
                        </b-button>
                    </div>
                </div>
            </template>
        </b-card>
        <ek-table
            :columns="HeaderDetails"
            no_delete
            @details="goToDetails"
            :items="teacherDto.units"
        >
            <template #table-header>
                <div class="d-flex p-1">
                    <unicon class="pr-1" fill="gray" name="book-open"></unicon>
                    <h3>وحدات المشتركة</h3>
                </div>
            </template>

            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "" }}
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { nullGuid } from "@Ekcore/util/global.js";

export default {
    components: {},
    props: {
        id: String,
    },
    data: () => ({
        nullGuid,
    }),
    computed: {
        ...mapState({
            HeaderDetails: (state) => state.teachers.HeaderDetails,
            teacherDto: (state) => state.teachers.teacherDto,
            rateDto: (state) => state.settings.rateDto,
        }),
    },
    methods: {
        ...mapActions([
            "getTeacherDetails",
            "updateTeacher",
            "blockTeacher",
            "unblockTeacher",
        ]),
        getFile($event) {
            this.teacherDto.Image = $event.file;
        },
        formDate(dateCreated) {
            let date = `${new Date(dateCreated).toLocaleDateString()}`;
            return date;
        },
        goToDetails({ row }) {
            this.$router.push({
                path: `/admin/units/${row.id}`,
                query: { name: row.name },
            });
        },
        submit() {
            this.$refs["form"].validate().then((suc) => {
                if (suc) {
                    this.updateTeacher({
                        id: this.teacherDto.id,
                        name: this.teacherDto.name,
                        email: this.teacherDto.email,
                        newPassword: this.teacherDto.newPassword,
                        rate: this.teacherDto.rate,
                        phoneNumber: this.teacherDto.phoneNumber,
                        Description: this.teacherDto.description,
                        Image: this.teacherDto.Image,
                    });
                }
            });
        },
        isBlock() {
            if (!this.teacherDto.isBlocked) {
                this.blockTeacher(this.id);
            } else this.unblockTeacher(this.id);
        },
    },
    created() {
        this.getTeacherDetails(this.id);
    },
};
</script>
